import { z } from 'zod';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import { emailSchema } from 'modules/shared/schemas';

const contactFormSchema = z.object({
  email: emailSchema,
  name: z.string().min(1, { message: ERROR_TRANSLATION_KEYS.required }),
  message: z.string().min(1, { message: ERROR_TRANSLATION_KEYS.required }),
  companyName: z.string().min(1, { message: ERROR_TRANSLATION_KEYS.required }),
  website: z.string().optional(),
  termsAccepted: z.literal(true).optional(),
  joinNewsletter: z.boolean().optional(),
});

type ContactFormFields = z.infer<typeof contactFormSchema>;

export { contactFormSchema };
export type { ContactFormFields };
