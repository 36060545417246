import { useTranslation } from 'next-i18next';
import { TextField } from '@carvertical/ui';
import cx from 'classnames';
import { FormField } from 'components/common/ui/forms';
import type { ContactFormFields } from './schemas';
import styles from './Fields.module.scss';

type FieldsProps = {
  variant?: 'horizontal' | 'vertical';
};

const FIELD_PROPS = {
  color: 'outlined',
  fullWidth: true,
  filled: true,
};

const Fields = ({ variant = 'horizontal', ...props }: FieldsProps) => {
  const { t } = useTranslation(['business', 'common']);

  const vertical = variant === 'vertical';

  const Field = FormField<ContactFormFields>;

  return (
    <div
      style={{ '--columns': vertical ? 1 : 2 }}
      className={cx(styles.root, variant && styles[variant])}
      {...props}
    >
      <Field name="name">
        <TextField label={t('contactSection.labels.name')} {...FIELD_PROPS} />
      </Field>

      <Field name="email">
        <TextField label={t('contactSection.labels.email')} {...FIELD_PROPS} />
      </Field>

      <Field name="companyName">
        <TextField label={t('contactSection.labels.companyName')} {...FIELD_PROPS} />
      </Field>

      <Field name="website">
        <TextField
          label={`${t('contactSection.labels.website')} ${vertical ? `(${t('common:general.optionalLabel')})` : ''}`}
          {...FIELD_PROPS}
        />
      </Field>
    </div>
  );
};

export { Fields };
