import { z } from 'zod';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import { emailSchema } from 'modules/shared/schemas';

const campaignFormSchema = z.object({
  email: emailSchema,
  name: z.string().min(1, { message: ERROR_TRANSLATION_KEYS.required }),
  companyName: z.string().min(1, { message: ERROR_TRANSLATION_KEYS.required }),
  termsAccepted: z.literal(true).optional(),
});

type CampaignFormFields = z.infer<typeof campaignFormSchema>;

export { campaignFormSchema };
export type { CampaignFormFields };
