import type { ButtonProps } from '@carvertical/ui';
import type { ImageProps } from 'next/image';
import type { ParseKeys } from 'i18next';
import type { OptionalMarketIdRecord } from 'types/market';

type Logo = ImageProps & { link?: string; id: string };

type Label = {
  labelKey: ParseKeys<'business'>;
};

type Action = {
  id: string;
  variant?: ButtonProps['variant'];
  className?: string;
  labelKey: `common:${ParseKeys}` | `business:${ParseKeys<'business'>}`;
};

enum MainActivity {
  CarDealer = 'Car dealer',
  Insurance = 'Insurance',
  Leasing = 'Leasing',
  Classified = 'Classified',
  Api = 'API',
}

type ContactFormValues = {
  name: string;
  email: string;
  companyName: string;
  website?: string;
  message: string;
  termsAccepted?: boolean;
  joinNewsletter?: boolean;
};

type ContentType = {
  type: 'business' | 'insurance' | 'leasing' | 'api';
};

type LandingType = 'dealership' | 'insurance' | 'leasing' | 'custom-partner' | 'api';

type B2bContactParams = ContactFormValues & {
  mainActivity: MainActivity;
  market: string;
  captchaResponse?: string;
};

type B2bContactResponse = {
  message: string;
};

type Config = {
  titleKey: ParseKeys<'business'>;
  subtitleKey: ParseKeys<'business'>;
  solutions: ParseKeys<'business'>[];
  actions: Action[];
};

type BusinessPagePartialRouteData = { campaign?: boolean; partnerId?: string };

type CampaignText = {
  title: string;
  description: string;
  cta: string;
  successTitle: string;
  successDescription: string;
};

type CampaignIllustration = {
  default: string;
  success: string;
  badges?: CampaignBadgeData[];
  className?: string;
  successClassName?: string;
};

type CampaignContent = {
  text: CampaignText;
  illustration?: CampaignIllustration;
  partner?: string;
};

type CampaignFormValues = {
  name: string;
  email: string;
  companyName: string;
};

type CampaignBadgeBasis = {
  className?: string;
  partner: string;
};

type CampaignSocialBadge = CampaignBadgeBasis & {
  followers: string;
  platform: 'youtube' | 'instagram';
};

type CampaignPartnerBadge = CampaignBadgeBasis & {
  foundedIn: string;
  vehiclesSold: string;
  luxury?: boolean;
};

type CampaignBadgeData = CampaignSocialBadge | CampaignPartnerBadge;
type CampaignPartnerId =
  | 'lauto'
  | 'simplicicar'
  | 'jeanlainheritage'
  | 'aaaauto'
  | 'excelcar'
  | 'tomasiauto';
type CampaignPartnerIdRecord<TPartnerData> = Record<CampaignPartnerId, TPartnerData>;
type CampaignType<TCampaignData> = OptionalMarketIdRecord<TCampaignData> &
  CampaignPartnerIdRecord<TCampaignData>;

export { MainActivity };
export type {
  B2bContactParams,
  B2bContactResponse,
  BusinessPagePartialRouteData,
  Logo,
  Label,
  LandingType,
  Action,
  ContactFormValues,
  ContentType,
  Config,
  CampaignBadgeData,
  CampaignContent,
  CampaignFormValues,
  CampaignPartnerBadge,
  CampaignType,
  CampaignPartnerIdRecord,
  CampaignPartnerId,
};
